<template>
  <div class="card-align user-select">
    <div class="card">
      <div class="card-body">
        <div class="pointer algin-right">
          <span @click="editViewDetails()" class="icon-box-new-user-detail">
            <feather-icon class="rotate" icon="EditIcon" size="25" />
          </span>
          <span @click="updateDetailsView()" v-if="editableValue">
            <button class="btn pointer btn-primary btn-sm margin-right" :disabled="!this.$store.state.auth.permissions.includes('user-update')">
              update
            </button>
          </span>
        </div>
        <div class="meetup-header d-flex align-items-center">
          <div class="my-auto">
            <div class="inline-flex mr-botm">
              <div>
                <label v-if="editableValue">Firstname</label>
                <div
                  id="username"
                  :class="[
                    { bordereditable: editableValue },
                    'font-Weight-700 mr-right-10',
                  ]"
                  :contenteditable="editableValue"
                >
                  <span v-if="!editableValue" class="icon-span">
                    <feather-icon
                      class="text-primary rotate"
                      icon="UserIcon"
                      size="20"
                    />
                  </span>
                  {{ currentData.firstname }}
                </div>
              </div>
              <div>
                <label v-if="editableValue">Lastname</label>
                <div
                  id="lastname"
                  :class="[
                    { bordereditable: editableValue },
                    'font-Weight-700',
                  ]"
                  :contenteditable="editableValue"
                >
                  {{ currentData.lastname }}
                </div>
              </div>
            </div>
            <div>
              <label v-if="editableValue">Email</label>
              <div
                id="email"
                :class="[
                  { bordereditable: editableValue },
                  'font-Weight-700 mr-botm',
                ]"
                :contenteditable="editableValue"
              >
                <span v-if="!editableValue" class="icon-span">
                  <feather-icon
                    class="text-primary rotate"
                    icon="MailIcon"
                    size="20"
                  />
                </span>
                {{ currentData.email }}
              </div>
            </div>
            <div>
              <b-form-group>
                <label class="label-text">Roles:</label>
                <div v-if="editableValue">
                  <b-form-checkbox-group
                    v-for="(role, index) in allRoles"
                    :id="role._id"
                    name="flavour-2"
                    class="demo-inline-spacing inline-flex"
                    v-model="selected"
                    :key="index"
                  >
                    <b-form-checkbox :value="role.name">
                      {{ role.name }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </div>
                <div class="d-flex mr-1" v-else>
                  <b-form-checkbox-group
                    v-for="(role, index) in allRoles"
                    :id="role._id"
                    name="flavour-2"
                    class="demo-inline-spacing inline-flex"
                    v-model="selected"
                    :key="index"
                  >
                    <b-form-checkbox :value="role.name" :disabled="true">
                      {{ role.name }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </div>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="media padding-card">
          <div class="media-aside mr-1 align-self-start">
            <span
              class="b-avatar badge-light-primary rounded"
              style="width: 34px; height: 34px"
              ><span class="b-avatar-custom"
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18px"
                  height="18px"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-calendar"
                >
                  <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="16" y1="2" x2="16" y2="6"></line>
                  <line x1="8" y1="2" x2="8" y2="6"></line>
                  <line x1="3" y1="10" x2="21" y2="10"></line></svg
              ></span>
              <!---->
            </span>
          </div>
          <div class="media-body">
            <h6 class="mb-0">Last update at</h6>
            <small>{{ currentData.updated_at.split("T")[0] }}</small>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import Vue from "vue";
import {
  BFormTextarea,
  BSidebar,
  VBToggle,
  BPagination,
  BFormGroup,
  BFormCheckbox,
  BForm,
  BFormCheckboxGroup,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  data: () => ({
    userId: "",
    currentData: [],
    editableValue: false,
    firstName: "",
    lastname: "",
    email: "",
    allRoles: [],
    selected: [],
    currentPage: 1,
    lastPage: null,
    perPage: 10,
    rows: 1,
    searchResult: "",
  }),
  components: {
    vSelect,
    BFormGroup,
    BFormCheckbox,
    BForm,
    BFormCheckboxGroup,
    BBadge,
  },
  props: {},
  watch: {},
  mounted() {
    this.userId = this.$route.params.id;
    this.getUser(this.userId);
    this.getRole();
  },
  methods: {
    getRole() {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/roles`,
      };
      this.$http(options)
        .then((res) => {
          this.allRoles = res.data.data;
          // this.allRoles.forEach(element => {
          //    element.checked = false
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUser(id) {
      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/users/${id}`,
      };
      this.$http(options)
        .then((res) => {
          this.currentData = res.data.data;
          res.data.data.role_ids.forEach((ele) => {
            this.allRoles.forEach((el) => {
              if (ele == el._id) {
                this.selected.push(el.name);
              }
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editViewDetails() {
      this.editableValue = true;
    },
    updateDetailsView() {
      const updateID = this.currentData._id;
      this.firstName = document.getElementById("username").innerText;
      this.lastname = document.getElementById("lastname").innerText;
      this.email = document.getElementById("email").innerText;
      let data = {
        firstname: this.firstName,
        lastname: this.lastname,
        email: this.email,
        roles: this.selected,
      };

      const options = {
        method: "PUT",
        data: data,
        headers: { "content-type": "application/json" },
        url: process.env.VUE_APP_ONECSF_API_URL + `/users/${updateID}`,
      };
      this.$http(options)
        .then((res) => {
          // this.firstName = ''
          // this.lastname =''
          // this.email = ''

          this.editableValue = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.flex-inline {
  display: inline-flex;
  margin-right: 5px;
}
.mr-right {
  margin-right: 5px;
}
.mr-botm {
  margin-bottom: 10px;
}
.card-design {
  display: flex;
  justify-content: space-between;
}
.mr-50 {
  margin-right: 100px !important;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.mr-right-10 {
  margin-right: 10px;
}
.padding-card {
  padding-bottom: 1.5rem;
}
.bordereditable {
  border: 1px solid #d1cdcd;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  outline: none;
  width: 400px;
  // height: 35px;
}
.algin-right {
  display: flex;
  align-items: center;
  justify-content: end;
}
.textarea-editable {
  -moz-appearance: textfield-multiline;
  -webkit-appearance: textarea;
  border: 1px solid #d1cdcd;
  font: medium -moz-fixed;
  font: -webkit-small-control;
  height: 65px;
  overflow: auto;
  padding: 2px;
  resize: both;
  width: 400px;
  border-radius: 5px;
  outline: none;
}
.font-Weight-700 {
  font-weight: 700;
}
.icon-box-new-user-detail {
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  display: inline-flex;
}
.meetup-header {
  margin-bottom: 2rem;
}
.meetup-day {
  border-right: 1px solid #d8d6de;
  padding-right: 1.3rem;
  margin-right: 1.3rem;
  text-align: center;
}
.mr-btm-5 {
  margin-bottom: 5px;
}
.label-text {
  display: block;
  color: #7367f0;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px !important;
}
.demo-inline-spacing > * {
  margin-top: 0 !important;
}
.meetup-header {
  margin-bottom: 1rem !important;
}
.icon-span {
  margin-right: 5px;
  align-items: center;
  justify-content: center;
}
.user-select {
  user-select: none;
}
</style>
